import { Routes, Route, useLocation } from "react-router-dom";
import Landing from "./Pages/home/Landing";
import NavBar from "./Components/NavBar";
import Footer from "./Components/Footer";
import Copyrights from "./Components/Copyrights";
import Privacy from "./Pages/Privacy";
import Terms from "./Pages/Terms";


function App() {
    const location = useLocation();
    const isMainPage = location.pathname === "/";



    return (
        <div className="wrapper">
            <header>
                <NavBar isMainPage={isMainPage} />
            </header>
            <main className="mt-20">
                <Routes>
                    <Route path="/" element={<Landing />} />
                    <Route path="/privacy" element={<Privacy />} />
                    <Route path="/terms" element={<Terms />} />
                </Routes>
            </main>
            <footer>
                <Footer />
                <Copyrights />
            </footer>
        </div>
    );
}

export default App;
