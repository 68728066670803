import React from "react";
import { Link } from "react-scroll";
import { Link as LinkRouter } from "react-router-dom";

const Copyrights = () => {
    return (
        <section className="copyright">
            <div className="container px-4 sm:px-8 lg:grid lg:grid-cols-2">
                <ul className="mb-4 list-unstyled p-small flex flex-row justify-center lg:justify-start gap-8 ">
                    
                    <li className="mb-2">
                        <LinkRouter to="/terms">Terms & Conditions</LinkRouter>
                    </li>
                    <li className="mb-2">
                        <LinkRouter to="/privacy">Privacy Policy</LinkRouter>
                    </li>
                </ul>
                <p className="pb-2 p-small statement">
                    Copyright ©{" "}
                    <LinkRouter to="#" className="no-underline">
                        Say Hi
                    </LinkRouter>
                    , 2023
                </p>
            </div>
        </section>
    );
};

export default Copyrights;
