import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import "swiper/css";
// import "swiper/css/navigation";
import { testimonialsData } from "../data-testimonials";

const Testimonials = () => {
    return (
        <section id="reviews" className="testimonials-bg">
            <div className="slider-1 py-12 md:py-32 ">
                <div className="container px-4 sm:px-8">
                    <Swiper
                        modules={[Navigation]}
                        className="lg:mx-4 xl:mx-8"
                        spaceBetween={50}
                        slidesPerView={1}
                        breakpoints={{
                            // Define responsive breakpoints
                            640: {
                                slidesPerView: 2, // 2 slides on screens wider than 640px
                            },
                            768: {
                                slidesPerView: 2, // 2 slides on screens wider than 768px
                            },
                            1024: {
                                slidesPerView: 3, // 3 slides on screens wider than 1024px
                            },
                        }}
                        navigation
                    >
                        {testimonialsData.map((testimonial) => (
                            <SwiperSlide
                                key={testimonial.id}
                                style={{ padding: "3rem 0px" }}
                                className="p-4 md:p-8 test-card"
                            >
                                <div className="bg-gray py-6 xl:py-16 px-6 lg:px-10 " >
                                    <img
                                        className="w-16 h-16 mx-auto rounded-full mb-4 md:w-20 md:h-20 "
                                        src={testimonial.image}
                                        alt="user"
                                    />
                                    <div className="card-body">
                                        <p className="italic mb-3 testim-text">
                                            {testimonial.text}
                                        </p>
                                        <p className="testimonial-author">
                                            {testimonial.author}
                                        </p>
                                    </div>
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>

                    {/* <div className="slider-container">
                        <div className="swiper-container card-slider">
                            <div className="swiper-wrapper">
                                <div className="swiper-slide">
                                    <div className="card bg-gray">
                                        <img
                                            className="card-image"
                                            src={user1}
                                            alt="alternative"
                                        />
                                        <div className="card-body">
                                            <p className="italic mb-3">
                                                It's been so fun to work with
                                                Pavo, I've managed to integrate
                                                it properly into my business
                                                flow and it's great
                                            </p>
                                            <p className="testimonial-author">
                                                Jude Thorn - Designer
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="swiper-slide">
                                    <div className="card">
                                        <img
                                            className="card-image"
                                            src="images/testimonial-2.jpg"
                                            alt="alternative"
                                        />
                                        <div className="card-body">
                                            <p className="italic mb-3">
                                                We were so focused on launching
                                                as many campaigns as possible
                                                that we've forgotten to target
                                                our loyal customers
                                            </p>
                                            <p className="testimonial-author">
                                                Roy Smith - Developer
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="swiper-slide">
                                    <div className="card">
                                        <img
                                            className="card-image"
                                            src="images/testimonial-3.jpg"
                                            alt="alternative"
                                        />
                                        <div className="card-body">
                                            <p className="italic mb-3">
                                                I've been searching for a tool
                                                like Pavo for so long. I love
                                                the reports it generates and the
                                                amazing high accuracy
                                            </p>
                                            <p className="testimonial-author">
                                                Marsha Singer - Marketer
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="swiper-slide">
                                    <div className="card">
                                        <img
                                            className="card-image"
                                            src="images/testimonial-4.jpg"
                                            alt="alternative"
                                        />
                                        <div className="card-body">
                                            <p className="italic mb-3">
                                                We've been waiting for a
                                                powerful piece of software that
                                                can help businesses manage their
                                                marketing projects
                                            </p>
                                            <p className="testimonial-author">
                                                Tim Shaw - Designer
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="swiper-slide">
                                    <div className="card">
                                        <img
                                            className="card-image"
                                            src="images/testimonial-5.jpg"
                                            alt="alternative"
                                        />
                                        <div className="card-body">
                                            <p className="italic mb-3">
                                                Searching for a great
                                                prototyping and layout design
                                                app was difficult but thankfully
                                                I found app suite quickly
                                            </p>
                                            <p className="testimonial-author">
                                                Lindsay Spice - Marketer
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="swiper-slide">
                                    <div className="card">
                                        <img
                                            className="card-image"
                                            src="images/testimonial-6.jpg"
                                            alt="alternative"
                                        />
                                        <div className="card-body">
                                            <p className="italic mb-3">
                                                The app support team is amazing.
                                                They've helped me with some
                                                issues and I am so grateful to
                                                the entire team
                                            </p>
                                            <p className="testimonial-author">
                                                Ann Blake - Developer
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="swiper-button-next"></div>
                            <div className="swiper-button-prev"></div>
                        </div>
                    </div> */}
                </div>
            </div>
        </section>
    );
};

export default Testimonials;
