
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getFunctions } from "firebase/functions";

//  Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyC5P3x-dEWYzM44lUO2oitfIbl_JX11Mew",
  authDomain: "app-say-hi.firebaseapp.com",
  projectId: "app-say-hi",
  storageBucket: "app-say-hi.appspot.com",
  messagingSenderId: "743299308118",
  appId: "1:743299308118:web:5c0d0ef4612b27b1eff9f7",
  measurementId: "G-YS05L0LT8L"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const functions = getFunctions(app);