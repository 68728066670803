import React from "react";
import emailIcon from "../assets/images/email-icon.png";
import circle1 from "../assets/images/circle1.png";
import circle2 from "../assets/images/circle2.png";

const Subscribe = () => {
    return (
        <section id="subscribe" className="subscribe text-center py-8 xl:py-32">
            <div className="about-decor">
                <div className="about-circle1">
                    <img src={circle1} alt="circle1" />
                </div>
                <div className="about-circle2" style={{bottom:0}}>
                    <img src={circle2} alt="circle2" />
                </div>
                
            </div>
            <div className="container  flex flex-col gap-8 items-center ">
                <img src={emailIcon} alt="email" className="w-fit m-inherit"/>
                <h2 className="title text-center md-margin-top">
                    subscribe to <span>newsletter</span>
                </h2>
                <p className="subs-desc px-4 lg:w-1/2">
                    There are many variations of passages of Lorem Ipsum
                    available, but the majority have suffered alteration in some
                    form, by injected humour.
                </p>
                <form
                    action=""
                    method="post"
                    id="subscribe-form"
                    name="subscribe-form"
                    className="flex flex-col gap-8"
                >
                    <div>
                        <input
                            type="email"
                            className="w-full px-3 py-2 border rounded-full focus:outline-none focus:border-blue-500"
                            placeholder="enter your email"
                            name="EMAIL"
                            id="mce-EMAIL"
                            required=""
                            style={{    minWidth: '18rem'}}
                        />
                    </div>
                    <div>
                        <button
                            type="submit"
                            className="btn-solid-reg rounded-full w-full sm:w-auto"
                        >
                            Send
                        </button>
                    </div>
                </form>
            </div>
        </section>
    );
};

export default Subscribe;
