import React from "react";
import phoneX from "../assets/images/about-phone.png";
import circle1 from "../assets/images/circle1.png";
import circle2 from "../assets/images/circle2.png";
import {
    FaBell, 
    FaMousePointer, 
    FaMagic, 
    FaRegObjectGroup, 
    FaUsers, 
    FaUsersCog, 
  } from 'react-icons/fa';

const About = () => {
    return (
        <section id="about" className="about-bg ">
            <div className="about-decor">
                <div className="about-circle1">
                    <img src={circle1} alt="circle1" />
                </div>
                <div className="about-circle2">
                    <img src={circle2} alt="circle2" />
                </div>
            </div>
            <div className="container py-12 px-4 flex flex-col lg:flex-row gap-8 items-center justify-around">
                <div className="w-full lg:w-4/12 flex flex-col gap-6 text-center lg:text-left ">
                    <h2 className="title">
                        about the <span>Say Hi app</span>
                    </h2>
                    <p className="caption-about">
                        "Say Hi" is more than just an app. It's a movement to
                        bring people together in an authentic and meaningful
                        way. Rediscover the joy of connecting with others,
                        explore shared interests, and make memories that will
                        last a lifetime.
                    </p>
                    <div className="flex flex-col  md:flex-row lg:flex-col xl:flex-row gap-4">
                        <div className="ml-12 flex lg:flex-col sm:mx-auto lg:mx-0 ">
                            <ul className="about-style flex flex-col gap-1 sm:gap-4 ">
                                <li>
                                    <div className="about-text flex items-center gap-4">
                                        <div className="about-icon">
                                            <FaBell />
                                        </div>
                                        <h3>Real-time notifications</h3>
                                    </div>
                                </li>
                                <li>
                                    <div className="about-text flex items-center gap-4">
                                        <div className="about-icon">
                                            <FaMousePointer />
                                        </div>
                                        <h3>Intuitive interface</h3>
                                    </div>
                                </li>
                                <li>
                                    <div className="about-text flex items-center gap-4">
                                        <div className="about-icon">
                                            <FaMagic />
                                        </div>
                                        <h3>Smart matching algorithms</h3>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div className="ml-12 flex lg:flex-col sm:mx-auto lg:mx-0">
                            <ul className="about-style flex flex-col gap-1 sm:gap-4 ">
                                <li>
                                    <div className="about-text flex items-center gap-4">
                                        <div className="about-icon">
                                            <FaRegObjectGroup />
                                        </div>
                                        <h3>Modern design</h3>
                                    </div>
                                </li>
                                <li>
                                    <div className="about-text flex items-center gap-4">
                                        <div className="about-icon">
                                            <FaUsers />
                                        </div>
                                        <h3>Community building</h3>
                                    </div>
                                </li>
                                <li>
                                    <div className="about-text flex items-center gap-4">
                                        <div className="about-icon">
                                            <FaUsersCog />
                                        </div>
                                        <h3>Inclusivity</h3>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="top-margin">
                        <button
                            type="button"
                            className="btn-solid-reg rounded-full w-4/5 sm:w-auto"
                        >
                            Read more
                        </button>
                    </div>
                </div>
                <div className="about-right items-center flex ">
                    <img src={phoneX} alt="phone-about" className="h-4/5" />
                </div>
            </div>
        </section>
    );
};

export default About;
