import React from "react";
import { cardsData } from "../data-features";

const Features = () => {
    return (
        <section id="features" className="features-bg ">
            <div className="cards-1">
                <div className="container px-4">
                    {cardsData.map((item) => {
                        return (
                            <div className="card" key={item.id}>
                                <div className="card-image">
                                    {item.icon}
                                </div>
                                <div className="card-body">
                                    <h5 className="card-title">
                                        {item.title}
                                    </h5>
                                    <p className="mb-4">
                                        {item.description}
                                    </p>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </section>
    );
};

export default Features;
