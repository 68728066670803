import React from "react";
import { FaFacebookF, FaLinkedinIn, FaInstagram, FaEnvelope } from "react-icons/fa";
import { Link } from "react-router-dom";

const Footer = () => {
    return (
        <div className="footer">
            <div className="container px-4 sm:px-8">
                <h5 className="mb-8 xl:w-4/5 lg:mx-auto text-center" style={{padding: '0 1.3rem'}}> 
                    You can reach the team at &nbsp;
                    <Link
                        className=" hover:text-gray-500"
                        to="mailto:say-hi@gmail.com"
                        style={{ color: "#fff" }}
                    >
                        say-hi@gmail.com 
                    </Link>
                    &nbsp; for any inquiries or assistance. We're here to ensure that
                    your experience with "Say Hi" is as smooth and enjoyable as
                    possible.
                </h5>
                <div className="flex justify-center items-center">
                    <span className="fa-stack">
                        <Link to="#">
                            <FaFacebookF />
                        </Link>
                    </span>

                    <span className="fa-stack">
                        <Link to="#">
                            <FaLinkedinIn />
                        </Link>
                    </span>

                    <span className="fa-stack">
                        <Link to="#">
                            <FaInstagram />
                        </Link>
                    </span>

                    <span className="fa-stack">
                        <Link to="mailto:say-hi@gmail.com">
                            <FaEnvelope />
                        </Link>
                    </span>
                </div>
            </div>
        </div>
    );
};

export default Footer;
