import React from "react";
import { Link } from "react-router-dom";
import phones1 from "../assets/images/phones1.png";
import apple from "../assets/images/appstore-badge.svg";
import google from "../assets/images/googleplay-badge.png";
import circle3 from "../assets/images/circle3.png";
import circle4 from "../assets/images/circle4.png";
import circle5 from "../assets/images/circle5.png";

const Header = () => {
    return (
        <section id="header" className="head-bg" style={{ marginTop: "-5rem" }}>
            <div className="home-decor">
                <div className="home-circle1">
                    <img src={circle3} alt="" />
                </div>
                <div className="home-circle2">
                    <img src={circle4} alt="" />
                </div>
                <div className="home-circle3">
                    <img src={circle5} alt="" />
                </div>
            </div>
            <div className="container ">
            <div className="head-block flex text-center lg:text-left items-center mx-0 mt-32 lg:mt-0 lg:mx-10 flex-col lg:flex-row">
                    <div className="head-col-left w-full sm:w-4/5 lg:w-1/2 sm:p-4">
                        <div className="mb-4 sm:mb-16 lg:mt-32 xl:mt-40 xl:mr-12">
                            <h1 className="h1 lg:h1-large mb-5">
                                Reconnecting people <br/>mobile application
                            </h1>
                            <p className="p-large mb-8 px-4 sm:px-0">
                                Download "Say Hi" today and start creating the
                                connections you've been missing in your life.
                                Say Hi to a world of possibilities!
                            </p>
                            <div className="flex flex-row items-center sm:items-start badges gap-4 justify-center lg:justify-start">
                                <Link to="#">
                                    <img
                                        src={apple}
                                        alt="apple-badge"
                                        className="h-12 hover:invert"
                                    />
                                </Link>
                                <Link to="#">
                                    <img
                                        src={google}
                                        alt="google-badge"
                                        className="h-12 hover:invert"
                                    />
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="head-col-right flex justify-center sm:justify-start lg:w-1/2 p-4">
                        <img src={phones1} alt="" className="lg:mt-32 w-full lg:w-4/5 " />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Header;
