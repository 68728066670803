import {
  FaUserPlus, 
  FaUndo, 
  FaClock, 
  FaShieldAlt, 
  FaHandshake, 
  FaCalendarAlt, 
} from 'react-icons/fa';
  
  export const cardsData = [
    {
      id: 1,
      icon: <FaUserPlus />,
      title: 'Make new connections',
      description:
        'Expand your social circle by sending a friendly "Say Hi" to people who share your interests and are in your vicinity. Whether you are new to a city or just looking to meet new people',
    },
    {
      id: 2,
      icon: <FaUndo />,
      title: 'Revive old friendships',
      description:
        'Simply input your interests and see who shares them nearby. You might just find that long-lost friend or someone who shares your passion for hiking, photography, or cooking',
    },
    {
      id: 3,
      icon: <FaClock />,
      title: 'Choose time and place',
      description:
        'Say Hi empowers you to take control of your social life. When you find someone you would like to meet, you can choose the perfect time and place that suits both of you',
    },
    {
      id: 4,
      icon: <FaShieldAlt />,
      title: 'Privacy and safety',
      description:
        'Say Hi ensures that your personal information is protected, and you can interact with others securely',
    },
    {
      id: 5,
      icon: <FaHandshake />,
      title: 'Real-life connections',
      description:
        "Say Hi is all about turning online interactions into real-life connections. It's a tool for building meaningful relationships and fostering a sense of community",
    },
    {
      id: 6,
      icon: <FaCalendarAlt />,
      title: 'Event planning',
      description:
        'Say Hi allows you to create events and invite others who share your interests. Whether it is a book club meeting, a fitness class, or a movie night, Say Hi makes event planning a breeze',
    },
  ];
  