import React, { useState } from "react";
import { Link as ScrollLink } from "react-scroll";
import logo from "../assets/images/logo.png";

const NavBar = ({ isMainPage }) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    return (
        <nav className="navbar fixed-top bg-white">
            <div className=" sm:px-4 lg:px-8 flex flex-wrap items-center justify-between lg:flex-nowrap">
                <a
                    className="inline-block mr-4 py-0.5 text-xl whitespace-nowrap hover:no-underline focus:no-underline"
                    href="/"
                >
                    <img src={logo} alt="say-hi-logo" className="h-16" />
                </a>

                <button
                    className={`background-transparent rounded text-xl leading-none hover:no-underline focus:no-underline lg:hidden lg:text-gray-400 ${
                        isMenuOpen ? "open" : ""
                    }`}
                    type="button"
                    data-toggle="offcanvas"
                    onClick={() => setIsMenuOpen(!isMenuOpen)}
                >
                    <span className="navbar-toggler-icon inline-block w-8 h-8 align-middle"></span>
                </button>

                <div
                    className={`navbar-collapse offcanvas-collapse lg:flex lg:flex-grow lg:items-center ${
                        isMenuOpen ? "block" : "hidden"
                    }`}
                    id="navbarsExampleDefault"
                >
                    <ul className="pl-0 mt-3 mb-2 ml-auto flex flex-col list-none lg:mt-0 lg:mb-0 lg:flex-row">
                        <li>
                            {isMainPage ? (
                                <ScrollLink
                                    className="nav-link uppercase"
                                    to="about"
                                    spy={true}
                                    smooth={true}
                                    offset={-70}
                                    duration={500}
                                    onClick={() => setIsMenuOpen(!isMenuOpen)}
                                >
                                    About
                                </ScrollLink>
                            ) : (
                                <a
                                    className="nav-link uppercase"
                                    href="/#about"
                                    onClick={() => setIsMenuOpen(!isMenuOpen)}
                                >
                                    About
                                </a>
                            )}
                        </li>
                        <li>
                            {isMainPage ? (
                                <ScrollLink
                                    className="nav-link uppercase"
                                    to="features"
                                    spy={true}
                                    smooth={true}
                                    offset={-70}
                                    duration={500}
                                    onClick={() => setIsMenuOpen(!isMenuOpen)}
                                >
                                    Features
                                </ScrollLink>
                            ) : (
                                <a
                                    className="nav-link uppercase"
                                    href="/#features"
                                    onClick={() => setIsMenuOpen(!isMenuOpen)}
                                >
                                    Features
                                </a>
                            )}
                        </li>
                        <li>
                            {isMainPage ? (
                                <ScrollLink
                                    className="nav-link uppercase"
                                    to="details"
                                    spy={true}
                                    smooth={true}
                                    offset={-70}
                                    duration={500}
                                    onClick={() => setIsMenuOpen(!isMenuOpen)}
                                >
                                    Details
                                </ScrollLink>
                            ) : (
                                <a
                                    className="nav-link uppercase"
                                    href="/#details"
                                    onClick={() => setIsMenuOpen(!isMenuOpen)}
                                >
                                    Details
                                </a>
                            )}
                        </li>
                        <li>
                            {isMainPage ? (
                                <ScrollLink
                                    className="nav-link uppercase"
                                    to="reviews"
                                    spy={true}
                                    smooth={true}
                                    offset={-70}
                                    duration={500}
                                    onClick={() => setIsMenuOpen(!isMenuOpen)}
                                >
                                    Reviews
                                </ScrollLink>
                            ) : (
                                <a
                                    className="nav-link uppercase"
                                    href="/#reviews"
                                    onClick={() => setIsMenuOpen(!isMenuOpen)}
                                >
                                    Reviews
                                </a>
                            )}
                        </li>

                        <li>
                            {isMainPage ? (
                                <ScrollLink
                                    className="nav-link uppercase"
                                    to="download"
                                    spy={true}
                                    smooth={true}
                                    offset={-70}
                                    duration={500}
                                    onClick={() => setIsMenuOpen(!isMenuOpen)}
                                >
                                    Download
                                </ScrollLink>
                            ) : (
                                <a
                                    className="nav-link uppercase"
                                    href="/#download"
                                    onClick={() => setIsMenuOpen(!isMenuOpen)}
                                >
                                    Download
                                </a>
                            )}
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
};

export default NavBar;
