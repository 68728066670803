import user1 from "./assets/images/testimonial-1.jpg";
import user2 from "./assets/images/testimonial-2.jpg";
import user3 from "./assets/images/testimonial-3.jpg";
import user4 from "./assets/images/testimonial-4.jpg";
import user5 from "./assets/images/testimonial-5.jpg";
import user6 from "./assets/images/testimonial-6.jpg";

export const testimonialsData = [
    {
        id: 1,
        image: user1,
        text: "Say Hi has completely transformed the way I connect with people. It's an amazing platform!",
        author: "Alice Johnson - Sales Manager",
    },
    {
        id: 2,
        image: user2,
        text: "The Say Hi app is a game-changer. It's intuitive, user-friendly, and perfect for staying in touch with friends and family.",
        author: "John Smith - Tech Enthusiast",
    },
    {
        id: 3,
        image: user3,
        text: "I love using Say Hi for networking events. It's a fantastic tool for making new connections and expanding my professional network.",
        author: "Emily Davis - Business Developer",
    },
    {
        id: 4,
        image: user4,
        text: "Say Hi has simplified my life. It's become an essential part of my daily routine for sending greetings and staying connected.",
        author: "Michael Lee - Sales Manager",
    },
    {
        id: 5,
        image: user5,
        text: "I can't imagine my life without Say Hi. It's the best app for sending warm wishes to friends and loved ones.",
        author: "Sophia Wilson - Teacher",
    },
    {
        id: 6,
        image: user6,
        text: "I'm amazed by the Say Hi app's versatility. It's equally useful for both personal and professional communication.",
        author: "David Clark - Entrepreneur",
    },
];
