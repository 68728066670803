import React from "react";
import details2 from "../assets/images/details-2.png";
import { Link } from "react-router-dom";
import { FaAngleRight } from "react-icons/fa";

const Details = () => {
    return (
        <section id="details" className="py-8 sm:py-12">
            <div className="px-8 flex flex-col lg:flex-row items-center justify-around gap-8 ">
                <div className="w-full">
                    <div className="mx-auto sm:mb-16 lg:mb-0 flex justify-center">
                        <img
                            className="w-full md:w-1/2 md:mt-8"
                            src={details2}
                            alt="details"
                        />
                    </div>
                </div>
                <div className="w-full">
                    <div className="xl:mt-12">
                        <h2 className="mb-6 lg:w-4/6 text-center md:text-left">
                            Platform integration and life time updates
                        </h2>
                        <ul className="list mb-7 space-y-2">
                            <li className="flex items-center">
                                <FaAngleRight />
                                <div>
                                    Features that will help you and your
                                    marketers
                                </div>
                            </li>
                            <li className="flex items-center">
                                <FaAngleRight />
                                <div>
                                    Smooth learning curve due to the knowledge
                                    base
                                </div>
                            </li>
                            <li className="flex items-center">
                                <FaAngleRight />
                                <div>
                                    Ready out-of-the-box with minor setup
                                    settings
                                </div>
                            </li>
                        </ul>
                        <div className="text-center sm:text-left">
                            <Link
                                className="btn-outline-reg w-4/5 sm:w-auto text-center"
                                to="#"
                            >
                                Details
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Details;
