import React from 'react'

const Privacy = () => {
  return (
    <main className="container mx-auto py-12 px-4">
    <h1 className="policy-title text-3xl font-semibold text-center mb-6 text-primary">
        Privacy Policy for the Say-Hi App and Website
    </h1>
    <div className="policy-date text-center text-gray-600 mb-4">
        Effective Date: 29/08/2023 (dd/mm/yyyy)
    </div>
    <p>
        This Privacy Policy describes how Say-Hi ("we," "our," or "us")
        collects, uses, and discloses your information when you use our mobile
        application ("App") and website ("Website") to connect with others and
        send greetings. By using the Say-Hi App and Website, you agree to the
        practices described in this Privacy Policy.
    </p>

    <h2 className="text-2xl font-semibold mt-6">
        1. Information We Collect
    </h2>

    <h3 className="pl-8 text-lg font-normal mt-4 ">
        1.1 User Profile Information
    </h3>
    <p>When you use the App and Website, we may collect:</p>
    <ul className="list-disc list-inside text-gray-800 ml-6">
        <li>User profile details, such as username, profile picture</li>
        <li>Interactions with other users</li>
        <li>Messages and greetings exchanged</li>
    </ul>

    <h3 className="pl-8 text-lg font-normal mt-4">1.2 Device Information</h3>
    <p>
        We may collect information about your device, including a unique device
        identifier. This identifier is used to improve your user experience and
        deliver messages efficiently.
    </p>

    <h3 className="pl-8 text-lg font-normal mt-4">1.3 Usage Information</h3>
    <p>
        We may collect information about how you use the App and Website, such
        as the frequency of messages sent and received. This data is used to
        enhance the functionality and performance of the App and Website.
    </p>

    <h2 className="text-2xl font-semibold mt-6">2. How We Use Your Information</h2>

    <h3 className="pl-8 text-lg font-normal mt-4">2.1 Providing Services</h3>
    <p>
        We use the collected information to provide you with access to the
        App's and Website's features, including sending and receiving
        greetings.
    </p>

    <h3 className="pl-8 text-lg font-normal mt-4">2.2 Personalization</h3>
    <p>
        We may use usage data to personalize your experience within the App and
        Website, such as suggesting new connections and greetings.
    </p>

    <h3 className="pl-8 text-lg font-normal mt-4">2.3 Communication</h3>
    <p>
        We may use your device identifier to send you important updates and
        notifications related to your interactions within the App and Website.
    </p>

    <h3 className="pl-8 text-lg font-normal mt-4">2.4 Analytics</h3>
    <p>
        We may analyze usage patterns and trends within the App and Website to
        improve their functionality, performance, and user experience.
    </p>

    <h2 className="text-2xl font-semibold mt-6">3. How We Share Your Information</h2>

    <h3 className="pl-8 text-lg font-normal mt-4">3.1 User Connections</h3>
    <p>
        Your user profile information, including greetings and messages, may be
        shared with other users as part of the App's core functionality.
    </p>

    <h3 className="pl-8 text-lg font-normal mt-4">3.2 Service Providers</h3>
    <p>
        We may share usage data with third-party service providers that assist
        us in operating, managing, and improving the App and Website.
    </p>

    <h3 className="pl-8 text-lg font-normal mt-4">3.3 Legal Requirements</h3>
    <p>
        We may disclose your information to comply with applicable laws,
        regulations, legal processes, or governmental requests.
    </p>

    <h3 className="pl-8 text-lg font-normal mt-4">3.4 Business Transfers</h3>
    <p>
        In the event of a merger, acquisition, or sale of all or a portion of
        our assets, your information may be transferred as part of the
        transaction.
    </p>

    <h2 className="text-2xl font-semibold mt-6">4. Data Security</h2>

    <p>
        We implement security measures to protect your information from
        unauthorized access, alteration, disclosure, or destruction. However, no
        data transmission over the internet or electronic storage is completely
        secure, and we cannot guarantee the absolute security of your
        information.
    </p>

    <h2 className="text-2xl font-semibold mt-6">5. Changes to this Privacy Policy</h2>

    <p>
        We may update this Privacy Policy from time to time to reflect changes
        in our practices or for other operational, legal, or regulatory reasons.
        The updated policy will be posted within the App and Website.
    </p>

    <h2 className="text-2xl font-semibold mt-6">6. Contact Us</h2>
    <p className="mb-8">
        If you have any questions or concerns about this Privacy Policy or our
        data practices, please contact us at{" "}
        <a href="mailto:say-hi@gmail.com">say-hi@gmail.com</a>.
    </p>
</main>

  )
}

export default Privacy