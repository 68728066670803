import React from "react";
import { functions } from "../../firebase";
import { httpsCallable } from "firebase/functions";
import "../../assets/css/styles.css";
import "../../assets/css/swiper.css";
import "../../assets/css/magnific-popup.css";
import About from "../../Components/About";
import Features from "../../Components/Features";
import Testimonials from "../../Components/Testimonials";
import Subscribe from "../../Components/Subscribe";
import DownloadSection from "../../Components/DownloadSection";
import Details from "../../Components/Details";
import Header from "../../Components/Header";

const Landing = () => {
    //testing Cloud functions
    // const fetchData = async () => {
    //     const hello = httpsCallable(functions, "myFunction");
    //     const result = await hello();
    //     console.log(result.data);
        
    // };

    // fetchData();
    return (
        <>
            <Header />
            <About />

            <Features />
            <Details />
            <Testimonials />
            <Subscribe />
            <DownloadSection />
        </>
    );
};

export default Landing;
