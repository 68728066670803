import React from "react";
import { Link } from "react-router-dom";
import apple from "../assets/images/appstore-badge.svg";
import google from "../assets/images/googleplay-badge.png";
import smart from "../assets/images/conclusion-smartphone.png";
import circle3 from "../assets/images/circle3.png";
import circle5 from "../assets/images/circle5.png";

const DownloadSection = () => {
    return (
        <section id="download" className="download-block py-16 md:py-32">
            <div className="home-decor">
                <div className="home-circle1">
                    <img src={circle3} alt="" />
                </div>
                
                <div className="home-circle3">
                    <img src={circle5} alt="" />
                </div>
            </div>
            <div className="container px-4 lg:px-32 lg:grid lg:grid-cols-2 flex flex-col items-center justify-center gap-8 lg:gap-0 text-center lg:text-left">
                <div className="mx-auto">
                    <img
                        src={smart}
                        alt="alternative"
                        style={{height: '32rem'}}
                    />
                </div>
                <div className="">
                    <p className="mb-9 text-gray-800 text-xl md:text-2xl  lg:text-3xl leading-9">
                        Reconnecting people mobile applications don’t get much
                        better than Say Hi. Download it today!
                    </p>
                    <div className="flex badges gap-4 justify-center lg:justify-start">
                        <Link to="#">
                            <img
                                src={apple}
                                alt="apple-badge"
                                className="h-12 hover:invert"
                            />
                        </Link>
                        <Link to="#">
                            <img
                                src={google}
                                alt="google-badge"
                                className="h-12 hover:invert"
                            />
                        </Link>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default DownloadSection;
